import { styled } from '@mui/material/styles'
import { IoIosArrowDropdownCircle } from "react-icons/io";

export const StyledArrowScrollIcon = styled(IoIosArrowDropdownCircle)(({ upDirection }) => ({
    position: 'fixed',
    zIndex: 9999,
    cursor: 'pointer',
    color: '#4C637B',
    fontSize: '3rem',
    borderRadius: '50%',
    background: 'transparent',
    right: '2px',
    bottom: '5px',
    opacity: 0.5,
    '&:hover': { opacity: 1 },
    transition: "transform 0.5s, opacity 0.2s ease-in-out",
    transform: upDirection ? 'rotate(-180deg)' : 'rotate(0deg)',
}))
import request from "../../utils/request"

export default {
    GetUserPermissionAccessLevel: () => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/getUserPermissionAccessLevel`,
            request.getOptions()
        )
        .then(res => {
            if (!res.ok) return Promise.reject({ status: res.status, message: "notHavePermissionToAccess" })
            else return res.json()
        })
    },
    GetAccessReleases: () => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/userProfilesByInstance`,
            request.getOptions()
        )
        .then(res => {
            if (!res.ok) return Promise.reject({ status: res.status, message: "notHavePermissionToAccess" })
            else return res.json()
        })
    },
    GetUserProfile: (codUser, codProfile) => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/get?codUser=${codUser}&codProfile=${codProfile}`,
            request.getOptions()
        )
        .then(res => {
            if (!res.ok) return Promise.reject({ status: res.status, message: "notHavePermissionToAccess" })
            else return res.json()
        })
    },
    GetUserWithoutProfileByEmail: (desEmail, codCompany, codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/getUserWithoutProfileByEmail?&desEmail=${desEmail}&codCompany=${codCompany}&codInstance=${codInstance}`,
            {...request.getOptions()}
        )
    },
    GetProfiles: () => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/allProfiles`,
            request.getOptions()
        )
        .then(res => {
            if (!res.ok) return Promise.reject({ status: res.status, message: "notHavePermissionToAccess" })
            else return res.json()
        })
    },
    InsertUserProfile: userProfile => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/insertUserProfile`,
            { 
                ...request.postOptions(), 
                body: JSON.stringify(userProfile) 
            }
        )
    },
    EditUserProfile: userProfile => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/editUserProfile`,
            { 
                ...request.putOptions(), 
                body: JSON.stringify(userProfile) 
            }
        )
    },
    DeleteAccessReleases: listToDelete => {
        return request.executeRequest(
            `${request.baseUrl}accessrelease/deleteUserProfile`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify(listToDelete)
            }
        )
    }
}
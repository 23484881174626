import React, { Component, Fragment } from 'react'
import {StyledArrowScrollIcon} from '../componentsUI/styledComponents/styledArrowScrollIcon'

class ScrollUpDownButton extends Component {
    constructor() {
      super()
      this.state = { upDirection: false }
    }

    componentDidMount = () => window.addEventListener('scroll', this.changeArrowScrollIconPosition)

    componentWillUnmount = () => window.removeEventListener('scroll', this.changeArrowScrollIconPosition)

    toggleArrowScrollIconPosition = upDirection => this.setState({ upDirection })

    toggleScrollPosition = scrollPosition => window.scrollTo({ top: scrollPosition })

    changeScrollPosition = () => {
        const { upDirection } = this.state;
        const scrollPosition  = this.calculateScrollPosition(upDirection)

        return this.toggleScrollPosition(scrollPosition)
    }

    changeArrowScrollIconPosition = () => {
        const upDirection = this.calculateArrowScrollIconPosition()

        return this.toggleArrowScrollIconPosition(upDirection)
    }
    
    calculateArrowScrollIconPosition = () => {
        const scrollPosition = window.scrollY
        const windowHeight = window.innerHeight
        
        return scrollPosition > windowHeight / 2
    }

    calculateScrollPosition = upDirection => {
        return upDirection ? 0 : document.body.scrollHeight
    }

    render = () => {
        const { upDirection } = this.state;

        return (
            <Fragment>
                <StyledArrowScrollIcon upDirection={upDirection} onClick={this.changeScrollPosition}/>
            </Fragment>
        )
    } 
}

export default ScrollUpDownButton
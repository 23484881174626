import React, { Component, Fragment } from "react"
import Translate from "../../i18n/translate"
import SideBarService from "./side-bar-service"
import SidebarNavigationPaths from "./side-bar-navigation-paths"
import { List, ListItemButton, Grid2, Collapse, MenuItem, ClickAwayListener } from "@mui/material"
import {
    StyledListItemText,
    StyledSubListItemText,
    StyledGridItemActions,
    StyledFloatingMenu,
    StyledMenuList,
    StyledArrowDownUpIcon,
    StyledHamburgerMenuIcon,
    StyledIntegrationsIcon,
    StyledRegisterIcon,
    StyledAdministrationIcon,
    StyledMaintenanceIcon,
    StyledPointSubMenuIcon
} from "../../componentsUI/styledComponents/styledSideBar"

class SideBarNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appState: props.appState,
            sidebarMenus: new Object,
            userPermissions: undefined,
            userPrivileges: undefined,
            openRegistrations: false,
            openIntegrations: false,
            openAdministration: false,
            openMaintenance: false
        }
    }

    componentDidMount = () => {
        Promise.all([
            SideBarService.GetUserPermissions().then(response =>
                response.map(userPermission => {
                    if (userPermission.codAccessLevel !== 3)
                        return userPermission.functions.function;
                }).filter(Boolean)
            ),
            SideBarService.GetUserPrivileges().then(response =>
                response.map(userPrivilege => userPrivilege.codTypePrivilege)
            )
        ])
            .then(([userPermissions, userPrivileges]) => {
                this.setState({
                    userPermissions,
                    userPrivileges,
                    sidebarMenus: this.checkUserPermissionsAndPrivileges(userPermissions, userPrivileges)
                })
            })
    }

    checkUserPermissionsAndPrivileges = (userPermissions, userPrivileges) => {
        const sidebarMenus = new Object

        const registrations = SidebarNavigationPaths.registrations
            .filter(menuItem => {
                return userPermissions.includes(menuItem.path);
            })
            .filter(menuItem => {
                if (menuItem.codTypePrivilege == null) return menuItem
                else if (userPrivileges.includes(menuItem.codTypePrivilege)) return menuItem
            })

        const integrations = SidebarNavigationPaths.integrations
            .filter(menuItem => {
                return userPermissions.includes(menuItem.path);
            })
            .filter(menuItem => {
                if (menuItem.codTypePrivilege == null) return menuItem
                else if (userPrivileges.includes(menuItem.codTypePrivilege)) return menuItem
            })

        const administration = SidebarNavigationPaths.administration
            .filter(menuItem => {
                if (menuItem.codTypePrivilege == null) return menuItem
                else if (userPrivileges.includes(menuItem.codTypePrivilege)) return menuItem
            })

        const maintenance = SidebarNavigationPaths.maintenance
            .filter(menuItem => {
                if (menuItem.codTypePrivilege == null) return menuItem
                else if (userPrivileges.includes(menuItem.codTypePrivilege)) return menuItem
            })

        if (registrations.length > 0) sidebarMenus['registrations'] = registrations
        if (integrations.length > 0) sidebarMenus['integrations'] = integrations
        if (administration.length > 0) sidebarMenus['administration'] = administration
        if (maintenance.length > 0) sidebarMenus['maintenance'] = maintenance

        return sidebarMenus
    }

    openCloseSubmenus = menuName => {
        const { openRegistrations, openIntegrations, openAdministration, openMaintenance } = this.state

        switch (menuName) {
            case "registrations":
                this.setState({ openRegistrations: !openRegistrations, openIntegrations: false, openAdministration: false, openMaintenance: false })
                break;
            case "integrations":
                this.setState({ openRegistrations: false, openIntegrations: !openIntegrations, openAdministration: false, openMaintenance: false })
                break;
            case "administration":
                this.setState({ openRegistrations: false, openIntegrations: false, openAdministration: !openAdministration, openMaintenance: false })
                break;
            case "maintenance":
                this.setState({ openRegistrations: false, openIntegrations: false, openAdministration: false, openMaintenance: !openMaintenance })
                break;
        }
    }

    closeAllSubmenus = () => {
        this.setState({
            openRegistrations: false,
            openIntegrations: false,
            openAdministration: false,
            openMaintenance: false
        })
    }

    hideShowSidebar = () => {
        this.closeAllSubmenus()
        this.props.hideShowSidebar()
    }

    redirectSideBarNavigationPath = pageTitle => {
        const { appState } = this.state

        window.location.replace(`#/${pageTitle}`)

        if (appState.openDrawer == false) this.closeAllSubmenus()
    }

    renderSubmenuNavigation = listNameItem => {
        const { appState, openRegistrations, openIntegrations, openAdministration, openMaintenance, sidebarMenus } = this.state

        switch (listNameItem) {
            case "registrations":
                return (
                    <Collapse in={openRegistrations}>
                        <List>
                            {sidebarMenus.registrations.map(subMenuItem => (
                                <ListItemButton
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    <StyledPointSubMenuIcon />
                                    <StyledSubListItemText hide={!appState.openDrawer} primary={Translate(subMenuItem.path)} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                )
            case "integrations":
                return (
                    <Collapse in={openIntegrations}>
                        <List>
                            {sidebarMenus.integrations.map(subMenuItem => (
                                <ListItemButton
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    <StyledPointSubMenuIcon />
                                    <StyledSubListItemText hide={!appState.openDrawer} primary={Translate(subMenuItem.path)} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                )
            case "administration":
                return (
                    <Collapse in={openAdministration}>
                        <List>
                            {sidebarMenus.administration.map(subMenuItem => (
                                <ListItemButton
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    <StyledPointSubMenuIcon />
                                    <StyledSubListItemText hide={!appState.openDrawer} primary={Translate(subMenuItem.path)} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                )
            case "maintenance":
                return (
                    <Collapse in={openMaintenance}>
                        <List>
                            {sidebarMenus.maintenance.map(subMenuItem => (
                                <ListItemButton
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    <StyledPointSubMenuIcon />
                                    <StyledSubListItemText hide={!appState.openDrawer} primary={Translate(subMenuItem.path)} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                )
        }
    }

    renderFloatingSubmenuNavigation = listNameItem => {
        const { openRegistrations, openIntegrations, openAdministration, openMaintenance, sidebarMenus } = this.state

        switch (listNameItem) {
            case "registrations":
                return (
                    <StyledFloatingMenu hide={!openRegistrations}>
                        <StyledMenuList>
                            {sidebarMenus.registrations.map(subMenuItem => (
                                <MenuItem
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    {Translate(subMenuItem.path)}
                                </MenuItem>
                            ))}
                        </StyledMenuList>
                    </StyledFloatingMenu>
                )
            case "integrations":
                return (
                    <StyledFloatingMenu hide={!openIntegrations}>
                        <StyledMenuList>
                            {sidebarMenus.integrations.map(subMenuItem => (
                                <MenuItem
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    {Translate(subMenuItem.path)}
                                </MenuItem>
                            ))}
                        </StyledMenuList>
                    </StyledFloatingMenu>
                )
            case "administration":
                return (
                    <StyledFloatingMenu hide={!openAdministration}>
                        <StyledMenuList>
                            {sidebarMenus.administration.map(subMenuItem => (
                                <MenuItem
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    {Translate(subMenuItem.path)}
                                </MenuItem>
                            ))}
                        </StyledMenuList>
                    </StyledFloatingMenu>
                )
            case "maintenance":
                return (
                    <StyledFloatingMenu hide={!openMaintenance}>
                        <StyledMenuList>
                            {sidebarMenus.maintenance.map(subMenuItem => (
                                <MenuItem
                                    onClick={() => this.redirectSideBarNavigationPath(subMenuItem.path)}>
                                    {Translate(subMenuItem.path)}
                                </MenuItem>
                            ))}
                        </StyledMenuList>
                    </StyledFloatingMenu>
                )
        }
    }

    renderMenusNavigation = () => {
        const { appState, sidebarMenus, openRegistrations, openIntegrations, openAdministration, openMaintenance } = this.state
        const menusNavigation = new Array

        Object.entries(sidebarMenus).forEach(([menuName]) => {
            let iconMenu
            let rotateArrowBoolean

            switch (menuName) {
                case 'registrations':
                    iconMenu = (<StyledRegisterIcon />)
                    rotateArrowBoolean = openRegistrations
                    break;
                case 'integrations':
                    iconMenu = (<StyledIntegrationsIcon />)
                    rotateArrowBoolean = openIntegrations
                    break;
                case 'administration':
                    iconMenu = (<StyledAdministrationIcon />)
                    rotateArrowBoolean = openAdministration
                    break;
                case 'maintenance':
                    iconMenu = (<StyledMaintenanceIcon />)
                    rotateArrowBoolean = openMaintenance
                    break;
            }

            menusNavigation.push({ menuName, iconMenu, rotateArrowBoolean })
        })

        return menusNavigation.map(menu => {
            return (
                <Fragment>
                    {!appState.openDrawer && this.renderFloatingSubmenuNavigation(menu.menuName)}
                    <ListItemButton onClick={() => this.openCloseSubmenus(menu.menuName)}>
                        {menu.iconMenu}
                        <StyledListItemText hide={!appState.openDrawer} primary={Translate(menu.menuName)} />
                        <StyledArrowDownUpIcon rotateArrow={menu.rotateArrowBoolean} hiddenSidebar={!appState.openDrawer} />
                    </ListItemButton>
                    {appState.openDrawer && this.renderSubmenuNavigation(menu.menuName)}
                </Fragment>
            )
        })
    }

    clickAwaySidebar = () => {
        const { appState } = this.state

        if (appState.openDrawer == false) this.closeAllSubmenus()
    }

    render() {
        return (
            <StyledGridItemActions container direction="column">
                <ClickAwayListener onClickAway={() => this.clickAwaySidebar()}>
                    <Grid2>
                        <List>
                            <ListItemButton onClick={() => this.hideShowSidebar()}>
                                <StyledHamburgerMenuIcon />
                            </ListItemButton>
                            {this.renderMenusNavigation()}
                        </List>
                    </Grid2>
                </ClickAwayListener>
            </StyledGridItemActions>
        )
    }
}

export default SideBarNavigation